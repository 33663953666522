<script lang="ts" setup>
import * as yup from 'yup'
import type { OptionItem } from '~/components/BaseSelect/BaseSelect.vue'
import { SocialType, UseMethodType, type FaucetAmount, type Social } from '~/types'

const { t } = useI18n()

const { networks, socials: socialNetworks } = useAppConfig()
const error = ref<string>()
const link = ref<string>()
const sendType = ref<FaucetAmount>()
const alert = {
  '500': {
    message: 'formSection.send500Succesfull',
    linkText: 'formSection.showTx',
  },
  '1M': {
    message: 'formSection.send1MEmailSuccesfull',
    linkText: 'formSection.openMail',
  },
  'CLAIM': {
    message: 'formSection.send1MSuccesfull',
    linkText: 'formSection.showTx',
  },
}

const { emailValidation } = useValidations()

const validationSchema = yup.object({
  use: yup.mixed<UseMethodType>().required(t('validation.use')),
  email: emailValidation.when('use', {
    is: UseMethodType.DEFI,
    then: () => emailValidation.required(t('validation.email')),
  }),
  chainId: yup.string().required(t('validation.network')),
  wallet: yup.string().required(t('validation.wallet')).matches(/^0x[a-fA-F0-9]{40}$/, t('validation.walletInvalid')),
  socials: yup.mixed<SocialType>().required(t('validation.socials')),
})

const { meta, handleSubmit, values, resetForm, setFieldValue } = useForm({
  validationSchema,
  initialValues: {
    use: UseMethodType.COMMON,
    chainId: '',
    wallet: '',
    email: undefined as string | undefined,
    socials: undefined as SocialType | undefined,
  },
})

const currentNetwork = computed(() => networks.find(network => network.chain.id.toString() === values.chainId))

const networkOptions = computed<OptionItem[]>(() => networks.map(network => ({
  label: network.name,
  value: network.chain.id.toString(),
  icon: network.icon,
})))

const isLoading = ref(false)

const { claimStatus, claimError } = useClaimStatus()
if (claimStatus.value) {
  sendType.value = claimStatus.value.type
  link.value = claimStatus.value.link
}
if (claimError.value)
  error.value = claimError.value

function handleAddressChanged (address: string) {
  setFieldValue('wallet', address)
}

const onSubmit = handleSubmit(async values => {
  error.value = undefined
  isLoading.value = true

  const amount: FaucetAmount = (values.use === UseMethodType.COMMON ? '500' : '1M')
  const { data, error: err } = await useFetch(`/api/give-czkc/${values.wallet}`, {
    method: 'POST',
    body: {
      amount: amount,
      chainId: values.chainId,
      userAgent: getUserAgent(window.navigator),
      origin: `${useRequestURL().origin}`,
      email: {
        head: {
          recipient: values.email,
          subject: t('email.subject'),
        },
        body: {
          message: t('email.message'),
        },
      },
    },
  })
  if (err.value) {
    console.error(err.value)
    error.value = err.value.data?.message
  }
  else {
    resetForm()
  }

  sendType.value = data.value?.sendStatus.type
  link.value = data.value?.sendStatus.link

  isLoading.value = false
})

const socials = computed<Social[]>(() => [
  ...socialNetworks,
  {
    name: t('formSection.dontFollow'),
    value: SocialType.NONE,
    link: undefined,
  },
])

const paymentMethods = [
  {
    name: 'common',
    title: 'CommonUse',
    amount: 500,
  },
  {
    name: 'defi',
    title: 'DeFi',
    amount: 1000000,
  },
]
</script>

<template>
  <div
    flex flex-col px-6 py-8 overflow-auto
    lg="gap-10px-8 justify-between h-full"
    xl:px-18
  >
    <Navigation mb-10 lg:mb-0 />
    <form
      v-auto-animate
      flex flex-col gap-5
      lg="gap-6"
      method="post"
      @submit="onSubmit"
    >
      <div v-if="sendType" rounded bg-blue-50 p-16px>
        <div flex items-center>
          <div flex-shrink-0>
            <UnoIcon i-heroicons-information-circle text="24px blue-400" aria-hidden="true" />
          </div>
          <div ml-12px flex-1 md:flex md:justify-between>
            <p text="sm blue-700" font-medium>
              {{ t(alert[sendType].message) }}
            </p>
            <p mt="12px md:0" md:ml-24px text-sm>
              <NuxtLink external :href="link" target="_blank" whitespace-nowrap font-medium text-blue="700 hover:600" underline>
                {{ t(alert[sendType].linkText) }}
              </NuxtLink>
            </p>
          </div>
        </div>
      </div>

      <div v-if="error" rounded-md bg-red-50 p-16px>
        <div flex items-center>
          <div flex-shrink-0>
            <UnoIcon i-heroicons-x-circle text="24px red-400" aria-hidden="true" />
          </div>
          <div ml-12px>
            <h3 text="sm red-800" font-medium>
              {{ t(error) }}
            </h3>
          </div>
        </div>
      </div>

      <BaseRadioGroupVee
        name="use"
        class="grid grid-cols-2 gap-3 lg:gap-4 h-fit"
      >
        <HeadlessRadioGroupLabel class="sr-only">
          Usage
        </HeadlessRadioGroupLabel>
        <HeadlessRadioGroupOption
          v-for="method in paymentMethods"
          :key="method.name"
          v-slot="{ checked }"
          :value="method.name"
          as="template"
        >
          <button
            type="button"
            radio-button-large
            :class="[
              checked
                ? 'bg-app-primary text-app-secondary'
                : 'border border-2 border-app-border text-app-text',
            ]"
          >
            <span>{{ t(`formSection.for${method.title}`) }}</span>
            <span space-x-1 :class="checked && 'font-extrabold text-app-accent-primary'">
              <span text-lg lg:text-3xl>
                {{ method.amount.toLocaleString() }}
              </span>
              <span czkc-text-size>
                CZKC
              </span>
            </span>
          </button>
        </HeadlessRadioGroupOption>
      </BaseRadioGroupVee>
      <BaseInputVee
        v-if="values.use === UseMethodType.DEFI"
        :label="t('formSection.email')"
        name="email"
        type="text"
        placeholder="john@doe.com"
      />
      <BaseSelectVee
        name="chainId"
        :label="t('formSection.chooseNetwork')"
        :placeholder="t('formSection.chooseNetwork')"
        :options="networkOptions"
      />
      <AddressInput
        name="wallet"
        :label="t('formSection.sendTo')"
        type="text"
        :placeholder="t('formSection.walletAddress')"
        @address-changed="handleAddressChanged"
        @error-occured="(err) => error = err"
      />
      <div flex flex-col gap-2>
        <label
          for="followUs"
          label-style
        >
          {{ t('formSection.followUs') }}
        </label>
        <BaseRadioGroupVee
          name="socials"
          :class="[
            'flex flex-col border-2 border-app-border divide-y-2 divide-app-border rounded-md text-app-accent-secondary font-medium',
            'md:grid md:grid-cols-2 md:gap-3 md:border-none md:divide-none md:rounded-none',
          ]"
        >
          <HeadlessRadioGroupOption
            v-for="social in socials"
            v-slot="{ checked }"
            :key="social.name"
            :value="social.value"
            as="template"
          >
            <NuxtLink
              v-if="social.link"
              :to="social.link"
              target="_blank"
              button-social
              :class="checked && 'button-social-checked'"
            >
              <NuxtImg
                :src="social.icon"
                w-6 block mr-1 md:hidden
              />
              <span>
                {{ t('formSection.follow') }}
                {{ social.name }}
              </span>
            </NuxtLink>
            <button
              v-else
              type="button"
              button-social
              :class="checked && 'button-social-checked'"
            >
              {{ social.name }}
            </button>
          </HeadlessRadioGroupOption>
        </BaseRadioGroupVee>
      </div>
      <button
        type="submit"
        :disabled="!meta.valid || isLoading"
        button-primary
      >
        <UnoIcon v-if="isLoading" i-svg-spinners-180-ring text="24px" />
        <p v-else>
          {{ t('formSection.send') }}
          {{ values.use === UseMethodType.COMMON ? '500' : (1000000).toLocaleString() }}
          CZKC
        </p>
      </button>
    </form>
    <div
      v-auto-animate
      flex flex-col items-center h-auto text="app-accent-secondary/50 sm" mt-6
      lg="flex-row justify-between mt-0"
    >
      <div
        v-if="currentNetwork"
        flex flex-col items-center
        lg="flex-row gap-1"
      >
        <p>
          {{ `${t('formSection.description1')} ${currentNetwork.chain.nativeCurrency.symbol}${t('formSection.description1a')}` }}
        </p>
        <p>
          <NuxtLink
            :to="currentNetwork.faucetURL"
            target="_blank"
            underline
          >
            {{ t('formSection.description2') }}
          </NuxtLink>
        </p>
      </div>
      <p v-if="currentNetwork">
        {{ t('formSection.need') }}
        <NuxtLink
          to="https://czkc.io"
          target="_blank"
          underline
        >
          {{ t('formSection.help') }}
        </NuxtLink>
      </p>
    </div>
  </div>
</template>
